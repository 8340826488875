import { filter, isArray, length, map, pipe, unique, uniqueBy } from 'remeda';

import { createSelector } from 'reselect';

import getUserId from 'services/auth-new';

import * as ROLES from 'utils/teammatesAccess/teammatesRoles';

import createMenuItems from './constants/menuItems';
import { fetchSignedInTeammate } from './slice';

export const getFetchTeammateResult = createSelector(
  fetchSignedInTeammate?.select(getUserId()),
  selected => selected?.data
);

export const getSignedInTeammateShortageGroup = createSelector(getFetchTeammateResult, teammate =>
  teammate?.groups?.find(teammateGroup => teammateGroup.name === ROLES.OPS_SHORTAGE_MANAGEMENT.name)
);

export const getSignedInTeammateProductionFactoriesByName = createSelector(
  getFetchTeammateResult,
  signedInTeammate => {
    if (!signedInTeammate?.production_cells) return null;
    return pipe(
      signedInTeammate?.production_cells,
      map(
        getSignedInTeammateProductionCell => getSignedInTeammateProductionCell?.production_factory
      ),
      uniqueBy(getSignedInTeammateProductionFactory => getSignedInTeammateProductionFactory?.name)
    );
  }
);

export const getSignedInTeammateProductionFactories = createSelector(
  getFetchTeammateResult,
  signedInTeammate => {
    if (!signedInTeammate?.production_cells) return null;

    if (signedInTeammate?.production_cells.length === 0) {
      return '';
    }

    return pipe(
      signedInTeammate?.production_cells,
      map(
        getSignedInTeammateProductionCell =>
          getSignedInTeammateProductionCell?.production_factory?.pubkey
      ),
      unique()
    );
  }
);

export const getMenuAccess = createSelector(getFetchTeammateResult, teammate => {
  const menuItems = createMenuItems(teammate).filter(item => {
    if (isArray(item.slug)) {
      const result = pipe(
        item.slug,
        map(slug => teammate?.permissions?.includes(slug)),
        filter(Boolean),
        length()
      );

      return result > 0;
    }
    return teammate?.permissions?.includes(item.slug);
  });

  return uniqueBy(menuItems, menuItem => menuItem.name);
});
