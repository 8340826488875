import React from 'react';

import Card from '@mui/material/Card';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useOmsSelector } from 'hooks/rtk';

import ErrorComponent from 'Components/ErrorComponent';
import CenteredProgress from '../Components/CenteredProgress';
import PageRoot from '../Components/PageRoot';

import getUserId from 'services/auth-new';

import * as TeammatesSelectors from 'dux/teammates/selectors';
import { useFetchSignedInTeammateQuery } from 'dux/teammates/slice';

const FlexContainer = styled('div')`
  display: flex;
`;

const StyledCard = styled(Card)`
  padding: 20px;
  min-height: 40px;
`;

const HomeScene = () => {
  const {
    status: loadingStatus,
    error,
    data: signedInTeammate,
  } = useFetchSignedInTeammateQuery(getUserId(), {
    skip: !getUserId(),
  });

  const factories = useOmsSelector(TeammatesSelectors.getSignedInTeammateProductionFactoriesByName);

  if (error) return <ErrorComponent error={error} />;
  if (loadingStatus === 'pending') return <CenteredProgress />;

  return (
    <PageRoot>
      <FlexContainer data-testid="welcome-page">
        <StyledCard>
          <Typography>
            Welcome{' '}
            <em>
              {signedInTeammate?.first_name} {signedInTeammate?.last_name}
            </em>{' '}
            !
          </Typography>
          <Typography>
            You are logged in as{' '}
            <code data-testid="welcome-username" style={{ backgroundColor: 'GainsBoro' }}>
              {signedInTeammate?.username}
            </code>
          </Typography>
          <br />
          <Typography data-sentry-unmask>
            Factor{factories?.length !== 1 ? 'ies' : 'y'} :
          </Typography>
          <ul data-testid="welcome-factories" data-sentry-unmask>
            {factories?.map(({ name }) => (
              <li key={name}>
                <Typography>{name}</Typography>
              </li>
            ))}
          </ul>
          <br />
          <Typography data-sentry-unmask>
            You are part of the following group{signedInTeammate?.groups?.length !== 1 ? 's' : ''} :
          </Typography>
          <ul data-testid="welcome-groups" data-sentry-unmask>
            {signedInTeammate?.groups?.map(({ name }) => (
              <li key={name}>
                <Typography>{name}</Typography>
              </li>
            ))}
          </ul>
        </StyledCard>
      </FlexContainer>
    </PageRoot>
  );
};

export default HomeScene;
