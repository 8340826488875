import backofficeApi from '../api/backoffice';
import type { EnhancedTeammate, FetchedTeammate } from './types';

const teammatesApi = backofficeApi.injectEndpoints({
  endpoints: builder => ({
    fetchSignedInTeammate: builder.query<FetchedTeammate, string | undefined>({
      query: userId => `/teammates/${userId}`,
    }),
    fetchTeammates: builder.query<EnhancedTeammate[], string>({
      query: url => url,
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchSignedInTeammateQuery,
  useFetchTeammatesQuery,
  endpoints: { fetchSignedInTeammate }, // used in selectors
} = teammatesApi;
