import React, { type FC, type ReactNode } from 'react';

import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import slice from 'lodash/fp/slice';
import uniq from 'lodash/fp/uniq';

import { Divider } from '@material-ui/core';

import getEnv from 'utils/getEnv';
import { isInTestEnvFromProcessEnv as isInTestEnv } from 'utils/isInTestEnv';

import type { MenuItem } from 'dux/teammates/types';

type GroupedMenu = Record<string, MenuItem[]>;

const constructMenu = (menu: MenuItem[], fillListItem: FC<MenuItem>): ReactNode => {
  if (isEmpty(menu)) return null;

  // we don't want to show the microhold screens in side menu on prod
  const filteredMenu = isInTestEnv(getEnv())
    ? menu
    : filter(item => item.group !== 'Microhold', menu);

  const preferredOrdering: string[] = flow(map('group'), uniq)(filteredMenu);
  const groupedMenu: GroupedMenu = groupBy('group')(filteredMenu);

  const menuWithDividers: ReactNode = flow(
    map((groupName: string) => [
      ...groupedMenu[groupName],
      <Divider key={`${groupName} Divider`} />,
    ]),
    flatten,
    map((menuItem: MenuItem) => (menuItem.group ? fillListItem(menuItem) : menuItem)),
    slice(0, -1)
  )(preferredOrdering);

  return menuWithDividers;
};

export default constructMenu;
