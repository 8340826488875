import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import split from 'lodash/fp/split';

import styled from '@mui/material/styles/styled';

import getUserId from 'services/auth-new';

import userHasRequiredPermissions from 'utils/userHasRequiredPermissions';

import { useFetchSignedInTeammateQuery } from 'dux/teammates/slice';

import CenteredProgress from './CenteredProgress';
import ErrorComponent from './ErrorComponent';
import NotAllowedComponent from './NotAllowedComponent';

const StyledDiv = styled('div')`
  padding: 15px;
`;

type Props = {
  permissions: string[];
};

const PermissionRoute = ({ permissions = [] }: Props) => {
  const { pathname } = useLocation();

  const {
    status,
    data: signedInTeammate,
    error,
  } = useFetchSignedInTeammateQuery(getUserId(), {
    skip: !getUserId(),
  });

  if (includes(status, ['idle', 'pending']) && isEmpty(signedInTeammate?.permissions)) {
    return <CenteredProgress />;
  }
  if (status === 'rejected') {
    return <ErrorComponent error={error} />;
  }

  if (isEmpty(signedInTeammate?.permissions)) {
    return <StyledDiv>You do not have any role assigned. Please, contact your manager</StyledDiv>;
  }

  if (isEmpty(signedInTeammate?.production_cells) && split('/', pathname)[1] === 'production') {
    return (
      <StyledDiv>
        You do not have production cells assigned. Please check with your manager
      </StyledDiv>
    );
  }

  if (userHasRequiredPermissions(signedInTeammate?.permissions, permissions)) {
    return <Outlet />;
  }
  return (
    <NotAllowedComponent necessaryPermissions={permissions} signedInTeammate={signedInTeammate} />
  );
};

export default PermissionRoute;
