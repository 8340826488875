import jwtDecode from 'jwt-decode';
import type { Nullable } from 'types/common';

import type { AuthTokens, DecodedJWT } from './types';

const AUTH_KEY = 'authentication';

const getAuth = (): Partial<AuthTokens> => {
  const value = localStorage.getItem(AUTH_KEY);
  return value ? JSON.parse(value) : {};
};

const getDecodedTokenUnmemoized = (token?: string): Nullable<DecodedJWT> => {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
};

type MemoizedFunction = typeof getDecodedTokenUnmemoized;
type FunctionParam = Parameters<MemoizedFunction>[0];

const memoizeOne = (func: MemoizedFunction) => {
  const cache = new Map<FunctionParam, ReturnType<MemoizedFunction>>();

  return (token: FunctionParam) => {
    if (cache.has(token)) return cache.get(token); // return cached value

    const value = func(token); // calculate the new value
    cache.clear(); // purge the cache because we want to save only one value
    cache.set(token, value);
    return value;
  };
};

const getDecodedToken = memoizeOne(getDecodedTokenUnmemoized);

const getUserId = () => {
  const { accessToken } = getAuth();

  const decoded = getDecodedToken(accessToken);
  return decoded?.user_id;
};

export default getUserId;
