import React from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SubscriptionIcon from '@mui/icons-material/Autorenew';
import DispatchIcon from '@mui/icons-material/CallSplit';
import ProductionIcon from '@mui/icons-material/Colorize';
import FastConsultationIcon from '@mui/icons-material/FlashOn';
import ExportIcon from '@mui/icons-material/GetApp';
import BufferIcon from '@mui/icons-material/GridOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/InvertColors';
import CustomerIcon from '@mui/icons-material/Person';
import GiftIcon from '@mui/icons-material/Redeem';
import ScienceSharpIcon from '@mui/icons-material/ScienceSharp';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import styled from '@mui/material/styles/styled';

import {
  AUTO_DISPATCH,
  BUFFER_SPACES_LIST,
  CUSTOMER_LIST,
  DATA_PORTAL,
  EXPORTS,
  FAST_CONSULTATION,
  FEEDBACK_LIST,
  GIFT_LIST,
  HELP,
  ORDER_LIST,
  PRINTERS_ADMIN,
  PRODUCTION_LIST,
  QUALITY_TEST_PALLETIZE_BOXES,
  QUALITY_TEST_PALLETS,
  SUBSCRIPTION_LIST,
  TEAMMATES_LIST,
} from 'utils/teammatesAccess/teammatesPermissions';
import getSubMenuAccess from '../utils';

import type { FetchedTeammate } from '../types';

const StyledPalletsIcon = styled(TableChartSharpIcon)`
  transform: scale(-1, -1);
`;

const adminPanelSubMenu = [
  {
    name: 'Printers',
    dataTestId: 'nav-printers',
    to: '/adminPanel/printers',
    slug: PRINTERS_ADMIN,
    group: 'Admin Printers',
  },
];

const createMenuItems = (teammate: FetchedTeammate | undefined) => [
  // CX
  {
    name: 'Customers',
    dataTestId: 'nav-customers',
    to: '/customers',
    icon: <CustomerIcon />,
    slug: CUSTOMER_LIST,
    group: 'CX',
  },
  {
    name: 'Orders',
    dataTestId: 'nav-orders',
    to: '/orders',
    icon: <OrderIcon />,
    slug: ORDER_LIST,
    group: 'CX',
  },
  {
    name: 'Subscriptions',
    dataTestId: 'nav-subscriptions',
    to: '/subscriptions',
    icon: <SubscriptionIcon />,
    slug: SUBSCRIPTION_LIST,
    group: 'CX',
  },
  {
    name: 'Feedbacks',
    dataTestId: 'nav-feedbacks',
    to: '/feedbacks',
    icon: <FeedbackIcon />,
    slug: FEEDBACK_LIST,
    group: 'CX',
  },
  {
    name: 'Gifts',
    dataTestId: 'nav-gifts',
    to: '/gifts',
    icon: <GiftIcon />,
    slug: GIFT_LIST,
    group: 'CX',
  },
  // Fulfillment
  {
    name: 'Production',
    dataTestId: 'nav-production',
    to: '/production',
    icon: <ProductionIcon />,
    slug: PRODUCTION_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Dispatch',
    dataTestId: 'nav-dispatch',
    to: '/production/dispatch/auto',
    icon: <DispatchIcon />,
    slug: AUTO_DISPATCH,
    group: 'Fulfillment',
  },
  {
    name: 'Buffer',
    dataTestId: 'nav-buffer',
    to: '/production/buffer-spaces',
    icon: <BufferIcon />,
    slug: BUFFER_SPACES_LIST,
    group: 'Fulfillment',
  },
  // Microhold
  {
    name: 'Quality Tests',
    dataTestId: 'nav-quality-tests',
    to: '/production/quality-tests/ongoing',
    icon: <ScienceSharpIcon />,
    slug: QUALITY_TEST_PALLETS,
    group: 'Microhold',
  },
  {
    name: 'Palletize Boxes',
    dataTestId: 'nav-palletize-boxes',
    to: 'production/palletize-boxes/',
    icon: <WidgetsSharpIcon />,
    slug: QUALITY_TEST_PALLETIZE_BOXES,
    group: 'Microhold',
  },
  {
    name: 'Pallets Management',
    dataTestId: 'nav-pallets-management',
    to: 'production/pallets-management/new',
    icon: <StyledPalletsIcon />,
    slug: QUALITY_TEST_PALLETS,
    group: 'Microhold',
  },
  // Data Analysis
  {
    name: 'Exports',
    dataTestId: 'nav-exports',
    to: '/exports',
    icon: <ExportIcon />,
    slug: EXPORTS,
    group: 'Data Analysis',
  },
  {
    name: 'Data Portal',
    dataTestId: 'nav-data-portal',
    to: '/dataportal',
    icon: <InsertChartIcon />,
    slug: DATA_PORTAL,
    group: 'Data Analysis',
  },
  // Administrate
  {
    name: 'Admin Panel',
    dataTestId: 'nav-admin-panel',
    to: null,
    icon: <AdminPanelSettingsIcon />,
    slug: [PRINTERS_ADMIN],
    group: 'Admin panel',
    subMenu: getSubMenuAccess(adminPanelSubMenu, teammate),
  },
  {
    name: 'Users',
    dataTestId: 'nav-teammates',
    to: '/teammates',
    icon: <GroupsIcon />,
    slug: TEAMMATES_LIST,
    group: 'Administration',
  },
  // QA
  {
    name: 'Fast',
    dataTestId: 'nav-fast-consultation',
    to: '/fastconsultation',
    icon: <FastConsultationIcon />,
    slug: FAST_CONSULTATION,
    group: 'Administration',
  },
  // Misc
  {
    name: 'Help',
    dataTestId: 'nav-help',
    to: '/help',
    icon: <HelpIcon />,
    slug: HELP,
    group: 'Misc',
  },
];

export default createMenuItems;
